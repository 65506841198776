let permissions = {
    ADMINISTRATION: {
        code: `ad`,
        icon: `fa-shield`,
        name: {en: `Administration`, fr: `Administration`},
        description: {
            en: `Users, roles and locations modules management`,
            fr: `Gestion des utilisateurs, des roles et des modules`
        },
        feature: {
            DEALERS: {
                code: `d`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PUBLIC_API: {
                code: `a`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            ROLES: {
                code: `r`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            USERS: {
                code: `u`,
                access_levels: `rwa`,
                name: {en: `Eye-Intelligence Users`, fr: ``}
            },
            EMAIL_REPORTS: {
                code: `er`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    CMS: {
        code: `cm`,
        icon: `fa-globe`,
        name: {en: `CMS & Website`, fr: `CMS & Site internet`},
        description: {en: `Editable Websites manageable from Eye-Intelligence`, fr: `Gestion des sites internet modifiables depuis Eye-Intelligence`},
        feature: {
            ADMIN: {
                code: `ad`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            ITEMS: {
                code: `cm`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            LIBRARY: {
                code: `li`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PAGES: {
                code: `pe`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            REPORT: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            },
            WEBSITES: {
                code: `wb`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    COUPONS: {
        code: `cp`,
        icon: `fa-ticket-alt`,
        name: {en: `Coupons`, fr: `Coupons`},
        description: {en: `Coupons campaigns on Login Page`, fr: `Campagnes de coupons sur la page de connexion`},
        feature: {
            COUPONS: {
                code: `c`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            }
        }
    },
    CUSTOMER_SERVICE: {
        code: `cs`,
        icon: `fa-comments`,
        name: {en: `Customer Service`, fr: `Service client`},
        description: {en: `Chat and Evaluation System`, fr: `Chat et système d'évaluation`},
        feature: {
            CHAT: {
                code: `c`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            EVALUATION: {
                code: `e`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            }
        }
    },
    DIGITAL_SIGNAGE: {
        code: `ds`,
        icon: `fa-tv-alt`,
        name: {en: `Digital Signage`, fr: `Affichage digital`},
        description: {
            en: `Multimedia Library, Playlist, and Player management`,
            fr: `Bibliothèque multimédia, listes de lecture et gestion des diffuseurs`
        },
        feature: {
            ALERTS: {
                code: `al`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            FILES: {
                code: `f`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            FILE_APPROBATION: {
                code: `ap`,
                access_levels: `w`,
                name: {en: ``, fr: ``}
            },
            FOLDERS: {
                code: `fo`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PLAYLISTS: {
                code: `p`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PLAYERS: {
                code: `pl`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PLAYER_ASSOCIATION: {
                code: `pa`,
                access_levels: `w`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            },
            UPLOAD_HQ: {
                code: `hq`,
                access_levels: `r`,
                name: {en: `Allow uploading above 4K (for Videowalls)`, fr: `Autoriser à uploader plus que 4K (pour les murs vidéos)`}
            },
            VIRTUAL_PLAYERS: {
                code: `vp`,
                access_levels: `w`,
                name: {en: ``, fr: ``}
            },
            WIDGETS: {
                code: `w`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            WIDGETS_DATA: {
                code: `wd`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    FOOD: {
        code: `fo`,
        icon: `fa-utensils-alt`,
        name: {en: `FoodForce`, fr: `FoodForce`},
        description: {
            en: `Manage Restaurants and menus`,
            fr: `Gestion des restaurants et des menus`
        },
        feature: {
            RESTAURANT_WORKSPACE: {
                code: `rw`,
                access_levels: `r`,
                icon: `fas fa-hat-chef`,
                name: {en: ``, fr: ``}
            },
            ADMIN_WORKSPACE: {
                code: `aw`,
                access_levels: `r`,
                icon: `fas fa-shield-alt`,
                uiClass: `border-bottom`,
                name: {en: ``, fr: ``}
            },
            BILLING: {
                code: `b`,
                access_levels: `rwa`,
                icon: `fa-dollar-sign`,
                name: {en: ``, fr: ``}
            },
            CASH_REGISTER: {
                code: `cr`,
                access_levels: `r`,
                icon: `fa-cash-register`,
                name: {en: ``, fr: ``}
            },
            COMPETITION_ANALYZER: {
                code: `ca`,
                access_levels: `r`,
                icon: `fa-radar`,
                name: {en: ``, fr: ``}
            },
            DELIVERY: {
                code: `d`,
                access_levels: `rw`,
                icon: `fa-truck`,
                name: {en: ``, fr: ``}
            },
            INVENTORY: {
                code: `in`,
                access_levels: `rw`,
                icon: `fa-inventory`,
                name: {en: ``, fr: ``}
            },
            KITCHEN_DISPLAY: {
                code: `kd`,
                access_levels: `r`,
                icon: `fa-tv`,
                name: {en: ``, fr: ``}
            },
            ORDERS: {
                code: `o`,
                access_levels: `rwa`,
                icon: `fa-clipboard-list`,
                name: {en: ``, fr: ``}
            },
            MARKETING: {
                code: `ma`,
                access_levels: `rwa`,
                icon: `fa-gift-card`,
                name: {en: ``, fr: ``}
            },
            MENUS: {
                code: `m`,
                access_levels: `rwa`,
                icon: `fa-plate-utensils`,
                name: {en: ``, fr: ``}
            },
            POS: {
                code: `po`,
                access_levels: `rwa`,
                icon: `fa-credit-card`,
                name: {en: ``, fr: ``}
            },
            PRICES: {
                code: `pr`,
                access_levels: `rwa`,
                icon: `fa-money-bill`,
                name: {en: ``, fr: ``}
            },
            PROMO_CODES: {
                code: `pc`,
                access_levels: `rwa`,
                icon: `fa-tag`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `re`,
                access_levels: `r`,
                icon: `fa-chart-line`,
                name: {en: ``, fr: ``}
            },
            RESTAURANTS: {
                code: `r`,
                access_levels: `rwa`,
                icon: `fa-store`,
                name: {en: ``, fr: ``}
            },
            RESTAURANT_GROUPS: {
                code: `g`,
                access_levels: `rwa`,
                icon: `fa-city`,
                name: {en: ``, fr: ``}
            },
            USERS: {
                code: `u`,
                access_levels: `rwa`,
                icon: `fa-id-card-alt`,
                name: {en: ``, fr: ``}
            },
            WEBSITE: {
                code: `w`,
                access_levels: `rwa`,
                icon: `fa-paint-brush-alt`,
                name: {en: ``, fr: ``}
            }
        }
    },
    GENERAL: {
        code: `ge`,
        icon: `fa-toolbox`,
        name: {en: `General Tools`, fr: `Outils généraux`},
        description: {
            en: `Smart Links`,
            fr: `Liens intelligents`
        },
        feature: {
            SMART_LINKS: {
                code: `ln`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    LOYALTY: {
        code: `ly`,
        icon: `fa-users-crown`,
        name: {en: `Loyalty`, fr: `Loyauté`},
        description: {
            en: `Members, Contests, Stores and Program Management`,
            fr: `Membres, concours, magasins et gestion du programme de loyauté`
        },
        feature: {
            ACHIEVEMENTS: {
                code: `a`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            CODES: {
                code: `co`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            CONTESTS: {
                code: `c`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            CUSTOMER_SERVICE: {
                code: `cs`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            EMAILS: {
                code: `em`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            EVENTS: {
                code: `e`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            MEMBERS: {
                code: `m`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PROGRAM: {
                code: `p`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PRIZES: {
                code: `z`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            RECEIPTS: {
                code: `t`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `r`,
                access_levels: `ra`,
                name: {en: ``, fr: ``}
            },
            SETTINGS: {
                code: `st`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            STORES: {
                code: `s`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    LICENSE: {
        code: `lc`,
        icon: `fa-file-certificate`,
        name: {en: `Licenses`, fr: `Licences`},
        description: {
            en: `Licenses  management`,
            fr: `Gestion des licenses`
        },
        feature: {
            MANAGEMENT: {
                code: `m`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            }
        }
    },
    MAPS: {
        code: `mp`,
        icon: `fa-map`,
        name: {en: `Maps`, fr: `Plans`},
        description: {en: `Customer Journey and Map Editor`, fr: `Parcours client et éditeur de plan`},
        feature: {
            CUSTOMER_JOURNEY: {
                code: `c`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            MAPS: {
                code: `m`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            MAP_EDITOR: {
                code: `me`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            }
        }
    },
    NEWSLETTERS: {
        code: `nw`,
        icon: `fa-envelope`,
        name: {en: `Newsletter`, fr: `Infolettre`},
        description: {
            en: `Email Designer, Marketing Campaigns, Members and Segments management`,
            fr: `Editeur d'emails, campagnes marketing, gestion des membres et segmentation`
        },
        feature: {
            EMAIL_DESIGNER: {
                code: `e`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            MEMBERS: {
                code: `m`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            SEGMENTS: {
                code: `s`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            SUBSCRIPTION_TOOLS: {
                code: `st`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            }
        }
    },
    STORYBOARD: {
        code: `sb`,
        icon: `fa-brush`,
        name: {en: `Storyboard Software`, fr: `Logiciel Storyboard`},
        description: {
            en: `Gives access to the Storyboard software`,
            fr: `Donne accès au logiciel Storyboard`
        },
        feature: {
            LITE: {
                code: `li`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PRO: {
                code: `pr`,
                access_levels: `rwa`,
                name: {en: ``, fr: ``}
            },
            PREMIUM_FEATURES: {
                code: `pf`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            TEMPLATES: {
                code: `tp`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            }
        }
    },
    SURVEYS: {
        code: `su`,
        icon: `fa-clipboard-list-check`,
        name: {en: `Surveys & Contests`, fr: `Sondages et concours`},
        description: {
            en: `Gives access to the survey and contest system`,
            fr: `Donne accès à la gestion de sondages ou de concours`
        },
        feature: {
            CONTESTS: {
                code: `c`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            SURVEYS: {
                code: `s`,
                access_levels: `rw`,
                name: {en: ``, fr: ``}
            },
            REPORTS: {
                code: `rp`,
                access_levels: `r`,
                name: {en: ``, fr: ``}
            }
        }
    },
    WIFI: {
        code: `wf`,
        icon: `fa-wifi`,
        name: {en: `Wi-Fi Network`, fr: `Réseau Wi-Fi`},
        description: {en: `Wi-Fi Network Management and Analytics`, fr: `Gestion de réseau Wi-Fi et analytique`},
        feature: {
            WORKSPACE_BETA: {
                code: `wb`,
                access_levels: `r`,
                icon: `fa-house-flag`,
                name: {en: `WiFi CRM 360 Workspace`, fr: `Espace de travail CRM Wi-Fi 360`},
                description: {en: `Warning: Only select one workspace to avoid confusion`, fr: ``}
            },
            WORKSPACE_LEGACY: {
                code: `wl`,
                access_levels: `r`,
                icon: `fa-house`,
                name: {en: `Legacy WiFi Workspace`, fr: ``},
                description: {en: `Warning: Only select one workspace to avoid confusion`, fr: ``}
            },
            CONTENTS: {
                code: `cm`,
                access_levels: `rwa`,
                icon: `fa-photo-film`,
                name: {en: `Login Visuals / Videos`, fr: ``}
            },
            GAMIFICATION: {
                code: `ga`,
                access_levels: `rwa`,
                icon: `fa-gamepad-modern`,
                name: {en: `Login Games (Soon)`, fr: ``}
            },
            IFRAMES: {
                code: `if`,
                access_levels: `rwa`,
                icon: `fa-browser`,
                name: {en: `Embed Websites (Soon)`, fr: ``}
            },
            ANALYTICS: {
                code: `rp`,
                access_levels: `r`,
                icon: `fa-chart-mixed`,
                name: {en: `Reports & Realtime`, fr: ``}
            },
            PROFILES: {
                code: `pr`,
                access_levels: `rwa`,
                icon: `fa-address-book`,
                name: {en: `User Profiles`, fr: `Profils utilisateurs`}
            },
            MAC_FILTERS: {
                code: `f`,
                access_levels: `rwa`,
                icon: `fa-lock`,
                name: {en: `MAC Filters (allow/block)`, fr: ``}
            },
            NETWORK: {
                code: `n`,
                access_levels: `rwa`,
                icon: `fa-wifi`,
                name: {en: `Network Configuration`, fr: ``}
            },
            INVENTORY: {
                code: `in`,
                access_levels: `rwa`,
                icon: `fa-router`,
                name: {en: `Inventory & Hardware`, fr: ``}
            },
            GEOLOCATION: {
                code: `ge`,
                access_levels: `rw`,
                icon: `fa-location-dot`,
                name: {en: `Inventory Geolocation`, fr: ``}
            },
            MARKETING: {
                code: `m`,
                access_levels: `rw`,
                name: {en: `Marketing Dashboard (Legacy)`, fr: ``}
            },
            PREPAID_CARDS: {
                code: `pc`,
                access_levels: `rws`,
                name: {en: `Prepaid Cards (Legacy)`, fr: ``}
            },
            SMS_LOGIN: {
                code: `sl`,
                access_levels: `rw`,
                name: {en: `SMS Login (Legacy)`, fr: ``}
            },
            SPEED_INSIGHT: {
                code: `si`,
                access_levels: `r`,
                name: {en: `Speed Insight Report (Legacy)`, fr: ``}
            },
            WIFI_ACCOUNT: {
                code: `w`,
                access_levels: `rwa`,
                name: {en: `WiFi Accounts (Legacy)`, fr: ``}
            }
        }
    }
};

export let featuresByCode = {};
export let modulesByCode = {};

function getLiteralText(value) {
    if (value === null || value === undefined) {
        console.error(`Value should not be null or undefined`);
        return null;
    }
    if (value.length > 0) {
        value = value.replace(/_/g, ` `);
        value = value.toLowerCase();
        value = value[0].toUpperCase() + value.substr(1);
    }
    return value;
}

for (let moduleName in permissions) {
    let module = permissions[moduleName];

    if (module.name.en === ``) {
        module.name.en = getLiteralText(moduleName);
    }
    if (module.name.fr === ``) {
        module.name.fr = getLiteralText(moduleName);
    }

    for (let featureName in module.feature) {
        let feature = module.feature[featureName];
        if (featuresByCode[module.code + `/` + feature.code] !== undefined) {
            throw new Error(`Error module ${moduleName} has duplicated codes ${featureName}: ${module.code}/${feature.code}`);
        } else {
            feature.code = module.code + `/` + feature.code;
            if (feature.name.en === ``) {
                feature.name.en = getLiteralText(featureName);
            }
            if (feature.name.fr === ``) {
                feature.name.fr = getLiteralText(featureName);
            }
            featuresByCode[feature.code] = feature;
        }
    }
    modulesByCode[module.code] = module;
}

export default permissions;
